import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Logo from "../images/logo.svg"
import BGVideo from "../images/bg.mp4"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      form: {
        name: '',
        email: ''
      }
    }
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': 'contact',
        ...this.state.form,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => console.error(error))

    e.preventDefault()

    setTimeout(() => {
      this.setState({
        form: {
          name: '',
          email: '',
        },
      })
    }, 1000)
  }
  handleInputChange = e => {
    const { id, value } = e.target
    const { form } = this.state
    form[id] = value
    this.setState({ form })
  }
  render() {
    const { name, email } = this.state.form
    return (
      <form name="contact"
        method="POST"
        data-netlify="true" data-submitted={this.state.submitted} onSubmit={this.handleSubmit} className="ContactForm">
        <div className="thankyou"><h3>Thank you!</h3></div>
        <div className="content">
          <input onChange={this.handleInputChange} value={name} id="name" name="name" type="text" required placeholder="Your name" />
          <input onChange={this.handleInputChange} value={email} id="email" name="email" type="email" required placeholder="Your email" />
          <button type="submit">Keep me updated</button>
        </div>
        
      </form>
    )
  }
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    <video autoPlay loop muted>
      <source src={BGVideo} />
    </video>
    <div className="layout">
      <img src={Logo} alt="" />
      <h1>
        Predictive analytics, <br />
        powered by deep learning.
      </h1>
      <h2>For everyone.</h2>
      <ContactForm />
    </div>
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
